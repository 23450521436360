'use client';

import Button from '@/components/shared/button/Button';
import { Link } from '@/lib/router-events/patch-router/link';

interface Props {
  text: string;
  url: string;
  appearance: 'filled' | 'lined' | 'ghost';
  isBlock?: boolean;
  target?: string;
}

/**
 * link (a tag) that looks like button
 */
export default function LinkButton({ text, url, appearance, isBlock, target }: Props): JSX.Element {
  return (
    <Link href={url} target={target}>
      <Button text={text} aria-label={text} isBlock={isBlock} appearance={appearance} />
    </Link>
  );
}
